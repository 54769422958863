//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-775:_3884,_5804,_4845,_8204,_4728,_596,_3300,_9948;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-775')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-775', "_3884,_5804,_4845,_8204,_4728,_596,_3300,_9948");
        }
      }catch (ex) {
        console.error(ex);
      }